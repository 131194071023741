import React from 'react'
import { Link ,useParams} from 'react-router-dom'
import  Axios  from 'axios';
import * as DOMPurify from 'dompurify';
import { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { domain } from '../env';


export const Scarsvdetails = () => {


    const [scarsvsingle,setScarsvsingle]=useState([])  
    const {id}=useParams()
  
  
    const getScarsv = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/scarsv/${id}/`
        }).then(
            response => {
                setScarsvsingle(response.data)
              
              
            }
        )
    }
    useEffect(() => {
        getScarsv()
    }, [id])
  
  
    const [scarsvall,setScarsvall]=useState([])

    const getScarsvall = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/scarsv/`
        }).then(
            response => {
                setScarsvall(response.data)
                console.log(response.data,"scarsv  data .....................")
              
            }
        )
    }

    useEffect(() => {
        getScarsvall()
    }, [])


  return (
   <>
    <section class="page-header">
            <div class="page-header-bg"  style={{'backgroundImage':'url(images/v.jpg)'}}>
            </div>
            <div class="container">
                <div class="page-header__inner">
                    <h2>SCARSV বিস্তারিত</h2>
                    <ul class="thm-breadcrumb list-unstyled">
                        <li><a href="/">হোম</a></li>
                        <li><span>/</span></li>
                        <li class="active">SCARSV বিস্তারিত</li>
                    </ul>
                </div>
            </div>
        </section>
    


        <section class="blog-details">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-lg-7">
                        <div class="blog-details__left">
                            <div class="blog-details__img">
                                {/* <img src="images/blog/blog-details-img-1.jpg" /> */}
                                <LazyLoadImage 
                                effect="blur" 
                                src={`${domain}${scarsvsingle?.image}`}
                                // style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
                               
                                />
                            </div>
                            <div class="blog-details__content">
                              
                                <h3 class="blog-details__title"> {scarsvsingle?.title_bangla}
                                </h3>
                                <p class="causes-one__text"  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${scarsvsingle?.details_Bangla}`)}}   />

                            </div>
                       
                         
                           
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="sidebar">
                           
                            <div class="sidebar__single sidebar__post">
                                <h3 class="sidebar__title">Latest Posts</h3>
                                <ul class="sidebar__post-list list-unstyled">


                                    {scarsvall && scarsvall.slice(0,5).map((ps)=>(
                                        <li>
                                        <div class="sidebar__post-image">
                                        <LazyLoadImage 
                                effect="blur" 
                                src={`${domain}${ps?.image}`}
                                style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%','height':'90px'}}
                               
                                />
                                        </div>
                                        <div class="sidebar__post-content">
                                            <h3>
                                                <span class="sidebar__post-content-meta"><i
                                                        class="far fa-user-circle"></i> SCARSV</span>
                                                    <a href={`/scarsvdetails${ps?.id}`}>{ps?.title_bangla}</a>

                                            
                                            </h3>
                                        </div>
                                    </li>

                                    ))}
                               




                                  
                                </ul>
                            </div>
                            <div class="sidebar__single sidebar__category">
                                <h3 class="sidebar__title">Quick Links</h3>
                                <ul class="sidebar__category-list list-unstyled">
                                    <li><a href="/about">সম্পর্কিত <span class="icon-right-arrow"></span></a>
                                    </li>
                                    <li><a href="/blog">ব্লগ <span class="icon-right-arrow"></span></a>
                                    </li>
                                    <li><a href="/achivement">অর্জন <span
                                                class="icon-right-arrow"></span></a></li>
                                    <li><a href="/contact">যোগাযোগ <span
                                                class="icon-right-arrow"></span></a></li>
                                 
                                </ul>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </section>
   </>
  )
}
