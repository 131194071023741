import React from 'react'
import { Link ,useParams} from 'react-router-dom'
import  Axios  from 'axios';
import * as DOMPurify from 'dompurify';
import { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { domain } from '../env';
export const WhoDetails = () => {

  const [whosingle,setWhosingle]=useState([])  
  const {id}=useParams()


  const getWhoSingle = async () => {
      await Axios({
        method: "get",
        url:`${domain}/api/whowe/${id}/`
      }).then(
          response => {
            setWhosingle(response.data)
              
            
          }
      )
  }
  useEffect(() => {
    getWhoSingle()
  }, [id])





  return (
    <>
    
    <section class="page-header">
            <div class="page-header-bg" style={{'backgroundImage':'url(images/v.jpg)'}}>
            </div>
            <div class="container">
                <div class="page-header__inner">
                    <h2>আমরা কারা?</h2>
                    <ul class="thm-breadcrumb list-unstyled">
                        <li><a href="/">হোম</a></li>
                        <li><span>/</span></li>
                        <li class="active">{whosingle?.option_title_bangla}</li>
                    </ul>
                </div>
            </div>
        </section>
   

   
        <section class="about-page">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="about-page__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                            <div class="about-page__img">
                            <LazyLoadImage 
            effect="blur" 
            src={`${domain}${whosingle?.image}`}
            style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
           
      
            />
                                <img src="assets/images/resources/about-page-img-1.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="about-page__right">
                            <div class="section-title text-left">
                                <span class="section-title__tagline">get to know {whosingle?.option_title_bangla}</span>
                                <h2 class="section-title__title">{whosingle?.option_title_bangla}</h2>
                            </div>
                            <p class="about-page__text"  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${whosingle?.short_details_bangla}`)}} />
                          
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="cta-one">
            <div class="cta-one-shape" ></div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="cta-one__inner">
                            <div class="cta-one__left">
                                <div class="cta-one__icon">
                                    <span class="icon-support"></span>
                                </div>
                                <h2 class="cta-one__title">অন্যের জীবন পরিবর্তনে <br/> সহযোগিতা করতে
</h2>
                            </div>
                            <div class="cta-one__right">
                                <a href="/volunteerform" class="thm-btn cta-one__btn">স্বেচ্ছাসেবক হোন</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
