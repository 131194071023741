import React from 'react'
import { Link ,useParams} from 'react-router-dom'
import  Axios  from 'axios';
import * as DOMPurify from 'dompurify';
import { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { domain } from '../env';
export const Achivement = () => {

  const [achivement,setAchivement]=useState([])  



  const getachivement = async () => {
      await Axios({
        method: "get",
        url:`${domain}/api/achivement/`
      }).then(
          response => {
            setAchivement(response.data)
              
            
          }
      )
  }
  useEffect(() => {
    getachivement()
  }, [])

  return (
  <>
  
  <section class="page-header">
            <div class="page-header-bg" style={{'backgroundImage':'url(images/v.jpg)'}}>
            </div>
            <div class="container">
                <div class="page-header__inner">
                    <h2>আমাদের অর্জন</h2>
                    <ul class="thm-breadcrumb list-unstyled">
                        <li><a href="/">হোম</a></li>
                        <li><span>/</span></li>
                        <li class="active">অর্জন</li>
                    </ul>
                </div>
            </div>
        </section>



        <section class="about-page">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">


                      {achivement && achivement.map((ac)=>(
                      <p  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${ac?.detailsBangla}`)}}   />

                      ))}
                    </div>
                 
                </div>
            </div>
        </section>
 



  </>
  )
}
