import React from 'react'

import Odometer from 'react-odometerjs';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import * as DOMPurify from 'dompurify';
import 'react-accessible-accordion/dist/fancy-example.css';
import  Axios  from 'axios';
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { domain } from '../env';
import { Helmet } from 'react-helmet';

export const MixititemEn = () => {
  useEffect(()=>{
    const counters = document.querySelectorAll('.value');
    const speed = 300;
    
    counters.forEach( counter => {
       const animate = () => {
          const value = +counter.getAttribute('akhi');
          const data = +counter.innerText;
         
          const time = value / speed;
         if(data < value) {
              counter.innerText = Math.ceil(data + time);
              setTimeout(animate, 1);
            }else{
              counter.innerText = value;
            }
         
       }
       
       animate();
    });
    

  })
 
  const [approach,setApproach]=useState([])
  const [video,setVideo]=useState([])
  const [inspiration,setInspiration]=useState([])
  const [faq,setFaq]=useState([])
  const [makedifference,setMakedifference]=useState([])

  const [counter,setCounter]=useState([])
  const[countmysite,setCountmysite]=useState(1)
  const getApproacch = async () => {
      await Axios({
        method: "get",
        url:`${domain}/api/approach/`
      }).then(
          response => {
            setApproach(response.data)

            
          }
      )
  }
  useEffect(() => {
    getApproacch()
  }, [])
  
  const getVideo = async () => {
    await Axios({
      method: "get",
      url:`${domain}/api/video/`
    }).then(
        response => {
            setVideo(response.data)
          
        }
    )
}
useEffect(() => {
    getVideo()
}, [])





const getInspiration = async () => {
    await Axios({
      method: "get",
      url:`${domain}/api/Inspiration/`
    }).then(
        response => {
            setInspiration(response.data)
          

          
        }
    )
}
useEffect(() => {
    getInspiration()
}, [])





const getFaq = async () => {
    await Axios({
      method: "get",
      url:`${domain}/api/faq/`
    }).then(
        response => {
            setFaq(response.data)
          

          
        }
    )
}
useEffect(() => {
    getFaq()
}, [])








const getmakedifference = async () => {
    await Axios({
      method: "get",
      url:`${domain}/api/makedifference/`
    }).then(
        response => {
            setMakedifference(response.data)
          

          
        }
    )
}
useEffect(() => {
    getmakedifference()
}, [])



const getCounter = async () => {
    await Axios({
      method: "get",
      url:`${domain}/api/counter/`
    }).then(
        response => {
            setCounter(response.data)
          

          
        }
    )
}
useEffect(() => {
    getCounter()
}, [])


const getCounterapi = async () => {
    await Axios({
      method: "get",
      url:`https://api.countapi.xyz/hit/youthplanetbd/visits`
    }).then(
        response => {
            setCountmysite(response.data)
          

          
        }
    )
}
useEffect(() => {

  


getCounterapi()
}, [])


  return (
    <>
 <Helmet>
                <meta charSet="utf-8" />
             
                <script src='https://cdnjs.cloudflare.com/ajax/libs/Swiper/5.3.7/js/swiper.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery-appear/0.1/jquery.appear.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js'></script>

            </Helmet>
     <section class="quality-work">
            <div class="quality-work-shape"
                style={{'backgroundImage':'url(images/shapes/quality-work--shape.png)'}}></div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="quality-work__tab-box tabs-box">
                            <ul class="tab-buttons clearfix list-unstyled">
                                <li data-tab="#approach" class="tab-btn active-btn"><span>আমাদের পদ্ধতির </span></li>
                                <li data-tab="#inspiration" class="tab-btn"><span>সম্পূর্ণ অনুপ্রেরণা</span></li>
                                <li data-tab="#difference" class="tab-btn"><span>একটু ভিন্নতা</span></li>
                            </ul>
                            <div class="tabs-content">
                          
                                <div class="tab active-tab animated fadeInUp" id="approach">
                                    <div class="tab-content__inner">
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6">
                                                <div class="tab-content__inner-left">
                                                    <ul class="list-unstyled tab-content__inner-list">

                                                {approach && approach.map((ap)=>(
                                                        <li>
                                                        <div class="tab-content__icon">
                                                            <span class="icon-tick"></span>
                                                        </div>
                                                        <div class="tab-content__desc">
                                                            <h4 class="tab-content__title">{ap?.title_Bangla}</h4>
                                                            <p class="tab-content__text">{ap?.approach_details_Bangla}</p>
                                                        </div>
                                                    </li>

                                                ))}

                                                    






                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6">
                                                <div class="tab-content__inner-right ">
                                                    {video && video.map((vd)=>(
                                                        <div class="tab-content__video-link">
                                                        <div class="tab-content-video-bg"
                                                        style={{'backgroundImage':`url("${domain}${vd?.video_thumbnail_image}")`,'backgroundPosition':'center center'}}
                                                        >
                                                        </div>
                                                        <a href={`https://www.youtube.com/watch?v=${vd?.video_link}`}
                                                            class="video-popup">
                                                            <div class="tab-content__video-icon">
                                                                <span class="fa fa-play"></span>
                                                                <i class="ripple"></i>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    ))}
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          
                                <div class="tab  animated fadeInUp" id="inspiration">
                                    <div class="tab-content__inner">
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6">
                                                {inspiration && inspiration.map((ins)=>(
                                                <div class="tab-content__inner-left">
                                              
                                                
                                                <p class="tab-content__summery" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${ins?.inspiration_Bangla}`)}} />
                                                 
  
                                                </div>
                                                ))}
                                               
                                            </div>


                                            
                                            <div class="col-xl-6 col-lg-6">
                                                <div class="tab-content__inner-right  ">
                                                    <div class="faq-page__single">
                                                        <div class="accrodion-grp faq-one-accrodion"
                                                            data-grp-name="faq-one-accrodion-1">
                                                        
                                                            
                                                    

    

  
    <div class="faq-container accrodion">
      
  

  <Accordion>
  {faq && faq.map((fq)=>(
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                       {fq?.faq_title_Bangla}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                       {fq?.faq_details_Bangla}
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
         ))}
        </Accordion>

   




    </div>
    
    
   <script src="script.js"></script>


                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                       
                                <div class="tab  animated fadeInUp" id="difference">
                                    <div class="tab-content__inner">
                                        <div class="row">

                                            {makedifference && makedifference.map((MD)=>(
  <>
  <div class="col-xl-7 col-lg-7">
      <div class="tab-content__inner-left">



          <div class="tab-content__difference">

             
              <p class="tab-content__summery"  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${MD?.make_diffrence_Bangla}`)}} />
                
            
            
          </div>




      </div>
  </div>
  <div class="col-xl-5 col-lg-5">
      <div class="tab-content__inner-right ">
          <div class="tab-content__image">
          <LazyLoadImage 
                                effect="blur" 
                                src={`${domain}${MD?.image}`}
                                // style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
                               
                                />
                
          </div>
      </div>
  </div>


</>
                                            ))}

                                          


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        



{counter && counter.map((ct)=>(
      <section class="counter-one">
      <div class="container">
          <div class="row">
              <div class="col-xl-12">
                  <div class="counter-one__inner">
                      <ul class="list-unstyled counter-one__list">
                          
                          <li class="counter-one__single wow fadeInUp" data-wow-delay="100ms">
                              <div class="counter-one__icon">
                                  <span>
                                  <img src='images/cam.png' style={{'height':'80px'}} />

                                  </span>
                              </div>
                              <h3 class="value" akhi={ct?.Total_Campaign} >00</h3>
                              <p class="counter-one__text">Total Campaigns</p>
                            
                          </li>
                          
                          <li class="counter-one__single wow fadeInUp" data-wow-delay="200ms">
                              <div class="counter-one__icon counter-one__icon-clr-primary">
                                  <span >
                                  <img src='images/vol.png' style={{'height':'80px'}} />
                                  </span>
                              </div>
                              <h3 class="value" akhi={ct?.Happy_Volunteer}>00</h3>
                              <p class="counter-one__text">Happy Volunteers</p>
                          </li>
                          <li class="counter-one__single wow fadeInUp" data-wow-delay="300ms">
                              <div class="counter-one__icon counter-one__icon-clr-extra">
                                  <span class="icon-user">
                                      <img src='images/visitor.png' style={{'height':'80px'}} />
                                  </span>
                              </div>
                             <h3>{countmysite?.value}</h3>
                              <p class="counter-one__text">Visitors</p>
                             
                          </li>
                       
                      </ul>
                  </div>
              </div>
          </div>
      </div>
     
  </section>

))}
       
    </>
  )
}
