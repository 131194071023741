import React, { useEffect,useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'


import  Axios  from 'axios';


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { domain } from '../env';






import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Contact = () => {
    const notify = () => toast.success("Send your message!");
  const [setting,setStting]=useState([])  

const[name,setName]=useState([])
const[phone,setPhone]=useState([])
const[message,setMesssage]=useState([])
  
  
  const getSetting = async () => {
      await Axios({
        method: "get",
        url:`${domain}/api/setting/`
      }).then(
          response => {
              setStting(response.data)
              
            
          }
      )
  }
  useEffect(() => {
      getSetting()
  }, []) 

 
  const handelSubmit=(e)=>{
    e.preventDefault();
    e.target.reset();
  
    Axios({
      method:'post',
      url:`${domain}/api/contact`,
      data:{
          'name':name,
          'phone':phone,
          'message':message
    
      }
  }).then((response)=>{
       
  
     
      console.log(response.data,'successssssssssssssssssssssss')
      notify()
    
      
      
  })
  
  
  
  
  
  
  
  }

  
  return (
    <section class="contact-page">
            <div class="container">
            <ToastContainer theme="colored" />
                <div class="row">
                    <div class="col-xl-4 col-lg-5">
                        <div class="contact-page__left">
                            <div class="section-title text-left">
                                <span class="section-title__tagline">যোগাযোগ</span>
                                <h2 class="section-title__title">আমাদের সাথে যোগাযোগ করুন</h2>
                            </div>
                            <p class="contact-page__text">আপনার যদি কোন প্রশ্ন থাকে বা আপনি যদি আমাদের সাথে যোগাযোগ করতে চান তাহলে বার্তা পাঠান</p>
                                {setting && setting.map((sa)=>(
                            <div class="contact-page__social">
                         <a href={`/${sa?.twitter_link}`}><i class="fab fa-twitter"></i></a>
                        <a href={`/${sa?.facebook_link}`}><i class="fab fa-facebook"></i></a>
                        <a href={`/${sa?.linkedin_link}`}><i class="fab fa-linkedin"></i></a>
                        <a href={`/${sa?.instagram_link}`}><i class="fab fa-instagram"></i></a>
                            </div>
                                ))}
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-7">
                        <div class="contact-page__right">
                            <form 
                                onSubmit={handelSubmit}>
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="comment-form__input-box">
                                            <input type="text" required onChange={e=>setName(e.target.value)} placeholder="Your Name" name="name" />
                                        </div>
                                    </div>
                              
                                    <div class="col-xl-6">
                                        <div class="comment-form__input-box">
                                            <input type="number" required onChange={e=>setPhone(e.target.value)} placeholder="Phone Number" name="phone" />
                                        </div>
                                    </div>
                                  
                                </div>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="comment-form__input-box text-message-box">
                                            <textarea required onChange={e=>setMesssage(e.target.value)} name="message" placeholder="Write a Message"></textarea>
                                        </div>
                                        <div class="comment-form__btn-box">
                                            <button type="submit" class="thm-btn comment-form__btn">Send us a
                                                message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}
