import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import * as DOMPurify from 'dompurify';
import  Axios  from 'axios';
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { domain } from '../env';
export const WhoWeAre = () => {
    
    const [whoweare,setWhoweare]=useState([])

    const getWhoweare = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/whowe/`
        }).then(
            response => {
                setWhoweare(response.data)
                console.log(response.data,"wwwwwwwwwwwwwwwwwwwwwwww data .....................")
              
            }
        )
    }
    useEffect(() => {
        getWhoweare()
    }, [])
    
  return (
    <>
       <section class="we-believe">
            <div class="we-believe-map" style={{'backgroundImage':"url(images/shapes/we-believe-map.png)"}}></div>
            <div class="container">
                <div class="section-title text-center">
                  
                    <h2 class="section-title__title">আমরা কারা? </h2>
                </div>
                <div class="row who" >
                    <div class="col-xl-12">
                        <ul class="list-unstyled we-believe__list">

                      {whoweare && whoweare.map((who)=>(
                        <li class="we-believe__single wow fadeInUp" data-wow-delay="100ms">
                        <div class="girlstalk">
                            <span >
        <LazyLoadImage 
            effect="blur" 
            src={`${domain}${who?.image}`}
            style={{'height':'150px','borderRadius':'25% 10%','border':'1px solid #a020f0','borderStyle':'dashed'}}
            key={who?.id}
            />
                                {/* <img src='images/g.jpg' style={{'height':'150px','borderRadius':'25% 10%','border':'1px solid #a020f0','borderStyle':'dashed'}} /> */}

                            </span>
                        </div>
                        <h3 class="we-believe__title"><a href={`/whodetails${who?.id}`}>{who?.option_title_bangla} </a></h3>
                        <p class="we-believe__text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${who?.short_details_bangla.slice(0,60)}...`)}} /> 
                       
                        <div class="blog-one__bottom">

                            <a  href={`/whodetails${who?.id}`} class="blog-one__btn" style={{'color':'#A020F0'}}>বিস্তারিত..</a>
                            <a href="blog-details.html" class="blog-one__arrow"><span
                                    class="icon-right-arrow"></span></a>
                        </div>
                    </li>

                      ))}
                        
                            
                            {/* <li class="we-believe__single wow fadeInUp" data-wow-delay="200ms">
                                <div class="">
                                    <span>
                                    <img src='images/sdg.jpg' style={{'height':'150px','width':'auto','borderRadius':'25% 10%','border':'1px solid #a020f0','borderStyle':'dashed'}} />
                                    </span>
                                </div>
                                <h3 class="we-believe__title"><a href="contact.html">School camp on SDG- </a></h3>
                                <p class="we-believe__text">Lorem ipsum is simply free text available in the market
                                    wesbites.</p>
                                    <div class="blog-one__bottom">
                                    <a href="blog-details.html" class="blog-one__btn" style={{'color':'#A020F0'}}>Read More</a>
                                    <a href="blog-details.html" class="blog-one__arrow"><span
                                            class="icon-right-arrow"></span></a>
                                </div>
                            </li>
                            <li class="we-believe__single wow fadeInUp" data-wow-delay="300ms">
                                <div>
                                    <span >
                                    <img src='images/safe.jpg' style={{'height':'150px','borderRadius':'25% 10%','border':'1px solid #a020f0','borderStyle':'dashed'}} />
                                    </span>
                                </div>
                                <h3 class="we-believe__title"><a href="volunteers.html">Safety for women- </a></h3>
                                <p class="we-believe__text">Lorem ipsum is simply free text available in the market
                                    wesbites.</p>
                                    <br/>
                                    <div class="blog-one__bottom">
                                    <a href="blog-details.html" class="blog-one__btn" style={{'color':'#A020F0'}}>Read More</a>
                                    <a href="blog-details.html" class="blog-one__arrow"><span
                                            class="icon-right-arrow"></span></a>
                                            <hr/>
                                </div>
                            </li>
                            <li class="we-believe__single wow fadeInUp" data-wow-delay="400ms">
                                <div >
                                    <span >
                                    <img src='images/youth.jpg' style={{'height':'150px','borderRadius':'25% 10%','border':'1px solid #a020f0','borderStyle':'dashed'}} />

                                    </span>
                                </div>
                                <h3 class="we-believe__title"><a href="donations.html">Youth Empowerment- </a></h3>
                                <p class="we-believe__text">Lorem ipsum is simply free text available in the market
                                    wesbites.</p>
                                    <div class="blog-one__bottom">
                                    <a href="blog-details.html" class="blog-one__btn" style={{'color':'#A020F0'}}>Read More</a>
                                    <a href="blog-details.html" class="blog-one__arrow"><span
                                            class="icon-right-arrow"></span></a>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </section>




        
    </>
  )
}
