import React from 'react'
import  Axios  from 'axios';
import { useEffect, useState } from 'react'
import { domain } from '../env';
import Carousel from 'react-multi-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-multi-carousel/lib/styles.css';
export const WhatTheySayEn = () => {

    const [Whatsay,setWhatsay]=useState([])

    const getsay = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/whatsay/`
        }).then(
            response => {
                setWhatsay(response.data)
                console.log(response.data,"say  data .....................")
              
            }
        )
    }
    useEffect(() => {
        getsay()
    }, [])

	const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 800 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 800, min: 0 },
          items: 1
        }
      }


  return (
    <>
     <section class="testimonial-two testimonial-three">
            <div class="testimonial-two-bg"
                style={{'backgroundImage':"url(images/backgrounds/testimonial-two-bg.png)"}}>

                </div>
            <div class="container" >
                <div class="row">
                    <div class="col-xl-4 ">
                        <div class="testimonial-two__left">
                            <div class="section-title text-left">
                               
                                <h2 class="section-title__title">তারা যা 
 <br/> বলেছেন.</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8">
                        <div class="testimonial-two__right">
                            <div class="">
                            <Carousel responsive={responsive}   autoPlay={true}    keyBoardControl={true} showDots={true} ssr={true} arrows={false}  swipeable={false} infinite={true} >
                            {Whatsay && Whatsay.map((ws)=>(


<>
<div class="testimonial-two__content " style={{'height':'auto'}}>
    <div class="testimonial-two__quote">
        <span class="icon-quote"></span>
    </div>
    <p class="testimonial-two__text">{ws?.what_say_Bangla}</p>
</div>
<div class="testimonial-two__client-info">
    <div class="testimonial-two__client-img">
    <LazyLoadImage 
	 effect="blur" 
	 src={`${domain}${ws?.image}`}
	 style={{'height':'80px','width':'80px','border':'1px solid #e570e7','borderStyle':'dashed',}}
	
	     // style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
/>
        {/* <img src="images/testimonial/testimonials-2-2.jpg" /> */}
    </div>
    <div class="testimonial-two__client-details">
        <h5 class="testimonial-two__client-name">{ws?.name_bangla}</h5>
        <p class="testimonial-two__client-title">{ws?.occupation_bangla}</p>
    </div>
</div></>


                            ))}
                   
                                </Carousel>
                               
                             
                               


                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
      
                 
           
        </section>
    
    </>
  )
}
