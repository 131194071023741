import React from 'react'

export const VolunteerEn = () => {
  return (
   <>
   <section class="fundraishing">
            <div class="fundraishing-bg-box">
                <div class="fundraishing-bg jarallax" data-jarallax data-speed="0.2" data-imgPosition="50% 0%"
                    style={{'backgroundImage':'url(images/v.jpg)'}}></div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="fundraishing__inner">
                            <p class="fundraishing__sub-title">স্বেচ্ছাসেবক  হোন</p>
                            <h2 class="fundraishing__title">এটি আপনার <span>মানবিক</span> বিকাশে সহযোগিতা করবে</h2>
                            <div class="fundraishing__btn-box">
                                <a href="/volunteerform" class="thm-btn fundraishing__btn">স্বেচ্ছাসেবক  হোন</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   </>
  )
}
