import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import * as DOMPurify from 'dompurify';
import  Axios  from 'axios';
import { useEffect, useState } from 'react'
import { domain } from '../env';
export const ScarsvEn = () => {
    const [scarsvall,setScarsvall]=useState([])

    const getScarsv = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/scarsv/`
        }).then(
            response => {
                setScarsvall(response.data)
                console.log(response.data,"scarsv  data .....................")
              
            }
        )
    }
    useEffect(() => {
        getScarsv()
    }, [])
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 800 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 800, min: 0 },
          items: 1
        }
      };
  return (
   <>

   <section class="causes-one">
            <div class="container">
                <div class="section-title text-left">
                  
                    <h2 class="section-title__title">SCARSV..</h2>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                      
                        <Carousel responsive={responsive} autoPlay={true}  keyBoardControl={true}  ssr={true}  swipeable={true} infinite={true} >
                    {scarsvall && scarsvall.map((sc)=>(
                            <div class="causes-one__single" style={{'marginLeft':'20px',}}>
                            <div class="causes-one__img">
                                 <LazyLoadImage 
                                effect="blur" 
                                PlaceholderSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4Aa_t7qz4bXafttwgkxZP4KjWSdcStaic-8ffb7e4A9Xoy2bazqyUEtT3i2hUu7eIa5M&usqp=CAU"
                                src={`${domain}${sc?.image}`}
                                // style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
                                key={sc?.id}
                                
                                alt="Loading....."
                                />
                                    {/* <img src="images/causes/causes-1-1.jpg"/> */}
                                    <div class="causes-one__category">
                                   
                                    </div>
                                </div>
                                <div class="causes-one__content-box">
                                    <div class="causes-one__content">
                                        <h3 class="causes-one__title">
                                            <a href={`/scarsvdetails${sc?.id}`}>{sc?.title_bangla}</a>
                                        </h3>
                                        <p class="causes-one__text"  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${sc?.details_Bangla.slice(0,100)}...`)}}   />
                                           
                                    </div>
                                   
                                </div>
                            </div>
                               ))}
</Carousel>
                        
                       
                    </div>
                </div>
            </div>
        </section>
   </>
  )
}
