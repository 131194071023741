import React, { useEffect,useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'


import  Axios  from 'axios';


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { domain } from '../env';

export const Footer = () => {

    
    const [setting,setStting]=useState([])  

  
  
    const getSetting = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/setting/`
        }).then(
            response => {
                setStting(response.data)
                
              
            }
        )
    }
    useEffect(() => {
        getSetting()
    }, []) 
  return (  
    <>
     {setting && setting.map((sa)=>(
    <footer class="site-footer">
            <div class="site-footer-bg" style={{'backgroundImage':'url(images/backgrounds/site-footer-bg.jpg)'}}>
            </div>
            <div class="site-footer__top">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                            <div class="footer-widget__column footer-widget__about">
                                <div class="footer-widget__about-text-box">
                                    <p class="footer-widget__about-text">আপনার ডোনেট তাদের জীবনের মান পরিবর্তন
                                     করতে সাহায্য করবে</p>
                                </div>
                                <a href="/donate" class="donate-btn footer-donate__btn"> <i
                                        class="fa fa-heart"></i>
                                    ডোনেট করুন</a>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                            <div class="footer-widget__column footer-widget__links clearfix">
                                <h3 class="footer-widget__title">Links</h3>
                                <ul class="footer-widget__links-list list-unstyled clearfix">
                              
                                    <li><a href="/about">সম্পর্কিত</a></li>
                                    <li><a href="/scarsv">SCARSV</a></li>
                                    <li><a href="/achivement">অর্জন</a></li>
                                    <li><a href="/contact">যোগাযোগ</a></li>
                                </ul>
                            </div>
                        </div>
                  
                        <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                            <div class="footer-widget__column footer-widget__contact clearfix">
                                <h3 class="footer-widget__title">যোগাযোগ</h3>
                                <ul class="list-unstyled footer-widget__contact-list">
                                    <li>
                                        <div class="icon">
                                            <span class="icon-email"></span>
                                        </div>
                                        <div class="text">
                                            <a href="#">{sa?.email}</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon">
                                            <span class="icon-telephone"></span>
                                        </div>
                                        <div class="text">
                                            <a href="#">{sa?.phoneBn}</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon">
                                            <span class="icon-pin"></span>
                                        </div>
                                        <div class="text">
                                            <p>{sa?.addressBn}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="site-footer__bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="site-footer__bottom-inner">
                                <p class="site-footer__bottom-text">© Copyright 2023 by <a href="#">Youth Planet</a>
                                <p style={{'fontSize':'10px','color':'gray'}}>Design & Development : AL-Adnan-Mehedi</p>
                                </p>
                                <div class="site-footer__social">
                                <a href={`/${sa?.twitter_link}`}><i class="fab fa-twitter"></i></a>
                        <a href={`/${sa?.facebook_link}`}><i class="fab fa-facebook"></i></a>
                        <a href={`/${sa?.linkedin_link}`}><i class="fab fa-linkedin"></i></a>
                        <a href={`/${sa?.instagram_link}`}><i class="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
     ))}
    </>
  )
}
