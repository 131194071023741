import React from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


import * as DOMPurify from 'dompurify';
import  Axios  from 'axios';  
import { useEffect, useState } from 'react'
import { domain } from '../env';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ReactPaginate from 'react-paginate';
export const AllBlog = () => {


    const [allblog,setAllBlog]=useState([])

    const getAllBlog = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/blog/`
        }).then(
            response => {
                setAllBlog(response.data)
                // console.log(response.data,"wwwwwwwwwwwwwwwwwwwwwwww data .....................")
              
            }
        )
    }
    useEffect(() => {
        getAllBlog()
    }, [])


    const [currentItems, setCurrentItems] = useState([]);
const [pageCount, setPageCount] = useState(0);

const [itemOffset, setItemOffset] = useState(0);
const itemsPerPage = 6;
useEffect(() => {

  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  setCurrentItems(allblog.slice(itemOffset, endOffset));
  setPageCount(Math.ceil(allblog.length / itemsPerPage));
}, [itemOffset, itemsPerPage,allblog]);

const handlePageClick = (event) => {
  const newOffset = (event.selected * itemsPerPage) % allblog.length;
  console.log(
    `User requested page number ${event.selected}, which is offset ${newOffset}`
  );
  setItemOffset(newOffset);
};


window.scrollTo(0,200 )
  return (
    <>
    <section class="blog-page">
    <div class="container">
        <div class="row">
            {currentItems && currentItems.map((ab)=>(
      <div class="col-xl-4 col-lg-4 col-md-6">
           
      <div class="blog-one__single">
          <div class="blog-one__img">
          <LazyLoadImage 
            effect="blur" 
            src={`${domain}${ab?.image}`}
            // style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
           
      
            />
              <div class="blog-one__date">
                  <p>{ab?.date}</p>
              </div>
              <a href={`/blogdetails${ab?.id}`} >
                  <span class="news-one__plus"></span>
              </a>
          </div>
          <div class="blog-one__content">
              <ul class="list-unstyled blog-one__meta">
                  <li><a href={`/blogdetails${ab?.id}`} ><i class="far fa-user-circle"></i> ব্লগ</a></li>
     
               
              </ul>
              <h3 class="blog-one__title">
                  <a href={`/blogdetails${ab?.id}`} >{ab?.title_Bangla}</a>
              </h3>
              <p class="blog-one__text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${ab?.discription_Bangla.slice(0,140)}`)}} />
              
              <div class="blog-one__bottom">
                  <a href={`/blogdetails${ab?.id}`}  class="blog-one__btn">বিস্তারিত..</a>
                  <a href={`/blogdetails${ab?.id}`}  class="blog-one__arrow"><span
                          class="icon-right-arrow"></span></a>
              </div>
          </div>
      </div>
  </div>
            ))}
      
       
        </div>
    </div>
</section>

<div class="col-md-12 ">
         <div className='offset-md-5  '>
           
           
           <ReactPaginate
breakLabel="..."
nextLabel={<i className='fas fa-angle-double-right' />}
onPageChange={handlePageClick}
pageRangeDisplayed={6}
pageCount={pageCount}
previousLabel={<i className='fas fa-angle-double-left' />}
renderOnZeroPageCount={null}
containerClassName='pagination'
pageClassName='page-num'
previousLinkClassName="page-num"
nextLinkClassName="page-num"

activeLinkClassName="active"


/>
    </div>   
       </div>
</>
  )
}
