import logo from './logo.svg';
import { Helmet } from 'react-helmet'
import * as $ from 'jquery'

import './App.css';


import { BrowserRouter } from 'react-router-dom';



import { Navigate, Route, Routes } from "react-router-dom";
import React,{createContext,useReducer,useContext, useEffect} from "react";
import { HomeEn } from './English/HomeEn';
import { HeadEn } from './English/HeadEn';
import { SliderEn } from './English/SliderEn';
import { Footer } from './English/Footer';
import { WhoDetails } from './English/WhoDetails';
import { About } from './English/About';

import { ScarsvEn } from './English/ScarsvEn';
import { Scarsvdetails } from './English/Scarsvdetails';
import { LawyerForm } from './English/LawyerForm';
import { DoctorForm } from './English/DoctorForm';
import { ScarsvPage } from './English/ScarsvPage';
import { MediaGalleryEn } from './English/MediaGalleryEn';
import { MediaGalleryPage } from './English/MediaGalleryPage';
import { VolunteerForm } from './English/VolunteerForm';
import { AllBlog } from './English/AllBlog';
import { BlogDetails } from './English/BlogDetails';
import { Achivement } from './English/Achivement';
import { SupportUS } from './English/SupportUS';
import { DonateUs } from './English/DonateUs';
import { Contact } from './English/Contact';
import { Error } from './English/Error';
function App() {

  
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js';   //(This is external js url)
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, [])
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery-appear/0.1/jquery.appear.min.js';   //(This is external js url)
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, [])
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js';   //(This is external js url)
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, [])
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js';   //(This is external js url)
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, [])


  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'pifoxen.js';   //(This is external js url)
    script.async = true;
    document.body.appendChild(script);
  }, [])

 



  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'vendors/swiper/swiper.min.js';   //(This is external js url)
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, [])

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/5.3.7/js/swiper.min.js';   //(This is external js url)
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, [])
  
  return (
    <>
       <Helmet>
                <meta charSet="utf-8" />
             
                <script src='https://cdnjs.cloudflare.com/ajax/libs/Swiper/5.3.7/js/swiper.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery-appear/0.1/jquery.appear.min.js'></script>
                <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js'></script>

            </Helmet>
    <HeadEn />
    <Routes>
      <>
       <Route path="/" element={<HomeEn />} />
       <Route path="whodetails:id" element={<WhoDetails/>} />
       <Route path="about" element={<About/>} />
       <Route path="scarsvdetails:id" element={<Scarsvdetails/>} />
       <Route path="scarsv" element={<ScarsvPage/>} />
       <Route path="mediagallery" element={<MediaGalleryPage/>} />
       <Route path="volunteerform" element={<VolunteerForm/>} />
       <Route path="allblog" element={<AllBlog/>} />
       <Route path="blogdetails:id" element={<BlogDetails/>} />
       <Route path="achivement" element={<Achivement/>} />
       <Route path="support" element={<SupportUS/>} />
       <Route path="donate" element={<DonateUs/>} />
       <Route path="contact" element={<Contact/>} />

       <Route path="lawyerform" element={<LawyerForm/>} />
       <Route path="doctorform" element={<DoctorForm/>} />

       <Route path="*" element={<Error/>} />

       


        
        </>
    </Routes>
    <Footer />
    </>
  );
}

export default App;
