import React from 'react'

import { Link ,useParams} from 'react-router-dom'
import  Axios  from 'axios';
import * as DOMPurify from 'dompurify';
import { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { domain } from '../env';

export const DonateUs = () => {
    
    const [Donate,setDonate]=useState([])  



    const getDonate = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/donate/`
        }).then(
            response => {
              setDonate(response.data)
                
              
            }
        )
    }
    useEffect(() => {
      getDonate()
    }, [])
  return (
    <>
    <section class="page-header">
            <div class="page-header-bg" style={{'backgroundImage':'url(images/v.jpg)'}}>
            </div>
            <div class="container">
                <div class="page-header__inner">
                    <h2>আমাদের ডোনেট করুন</h2>
                    <ul class="thm-breadcrumb list-unstyled">
                        <li><a href="/">হোম</a></li>
                        <li><span>/</span></li>
                        <li class="active">ডোনেট</li>
                    </ul>
                </div>
            </div>
        </section>



        <section class="about-page">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">


                      {Donate && Donate.map((ac)=>(
                      <p  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${ac?.detailsBangla}`)}}   />

                      ))}
                    </div>
                 
                </div>
            </div>
        </section>

    </>
  )
}
