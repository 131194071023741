import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


import * as DOMPurify from 'dompurify';
import  Axios  from 'axios';  
import { useEffect, useState } from 'react'
import { domain } from '../env';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const BlogMedia = () => {
    const [blog,setBlog]=useState([])

    const getBlog = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/blog/`
        }).then(
            response => {
                setBlog(response.data)
                console.log(response.data,"wwwwwwwwwwwwwwwwwwwwwwww data .....................")
              
            }
        )
    }
    useEffect(() => {
        getBlog()
    }, [])
  return (
    <>
    
                <section class="blog-one">
                        <div class="container">
                            <div class="section-title text-center">
                            
                                <h2 class="section-title__title">ব্লগ এবং মিডিয়া.</h2>
                            </div>
                            <div class="row">


{blog && blog.slice(0,3).map((blg)=>(
  <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                            
  <div class="blog-one__single">
      <div class="blog-one__img">
      <LazyLoadImage 
            effect="blur" 
            src={`${domain}${blg?.image}`}
            // style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
           
      
            />
          <div class="blog-one__date">
              <p>{blg?.date}</p>
          </div>
          <a href={`/blogdetails${blg?.id}`}>
              <span class="news-one__plus"></span>
          </a>
      </div>
      <div class="blog-one__content">
          <ul class="list-unstyled blog-one__meta">
              <li><a href={`/blogdetails${blg?.id}`}><i class="far fa-user-circle"></i> ব্লগ এবং মিডিয়া
</a></li>
            
           
          </ul>
          <h3 class="blog-one__title">
              <a href={`/blogdetails${blg?.id}`}>{blg?.title_Bangla}</a>
          </h3>
          <p class="blog-one__text"  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(` ${blg?.discription_Bangla.slice(0,140)}`)}}>
          </p>
          <div class="blog-one__bottom">
              <a href={`/blogdetails${blg?.id}`}   class="blog-one__btn">বিস্তারিত..</a>
              <a href="blog-details.html" class="blog-one__arrow"><span
                      class="icon-right-arrow"></span></a>
          </div>
      </div>
  </div>
  </div>
))}
                    
            
             
                  






                   
                </div>
            </div>
        </section>

        
    
    </>
  )
}
