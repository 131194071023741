
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


import * as DOMPurify from 'dompurify';
import  Axios  from 'axios';  
import { useEffect, useState } from 'react'
import { domain } from '../env';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const MediaGalleryEn = () => {

	const [mediagallery,setMediagallery]=useState([])
	const mediagalleryget = async () => {
        await Axios({
          method: "get",
          url:`${domain}/api/mediagallery/`
        }).then(
            response => {
                setMediagallery(response.data)
                console.log(response.data,"media  data .....................")
              
            }
        )
    }
    useEffect(() => {
        mediagalleryget()
    }, [])
	const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 800 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 800, min: 0 },
          items: 1
        }
      }
  return (
   <>
  
   <section class="gallery-page gallery--carousel">
    
			<div class="container">
            <div class="section-title text-left">
                  
                  <h2 class="section-title__title">মিডিয়া গ্যালারি..</h2>
              </div>
			  <Carousel responsive={responsive}  autoPlay={true}  keyBoardControl={true}  ssr={true}  swipeable={true} infinite={true} >

			
			  {mediagallery && mediagallery?.map((mg)=>(

<div class="col-md-4">
	<a href={`${domain}${mg?.image}`}>
	 <LazyLoadImage 
	 effect="blur" 
   laceholderSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4Aa_t7qz4bXafttwgkxZP4KjWSdcStaic-8ffb7e4A9Xoy2bazqyUEtT3i2hUu7eIa5M&usqp=CAU"
	 src={`${domain}${mg?.image}`}
	 style={{'height':'280px','border':'1px solid #e570e7','borderStyle':'dashed',}}
	
	     // style={{'border':'1px solid #e570e7','borderStyle':'dashed','borderRadius':'25% 10%',}}
/>
</a>
	 

</div>
))}

</Carousel>



{/* <div class="item">
 <div class="two-section__gallery-single">
	 <div class="two-section__gallery-img-inner">

/>
		 
	 </div>
	 <div class="two-section__gallery-img-overly">
		 <div class="two-section__gallery-icon-bg">
		 </div>
		 <a class="img-popup" href={`${domain}${mg?.image}`}>
			 <span class="icon-right-arrow"></span>
		 </a>
	 </div>
 </div>
</div> */}
		






				
					
				</div>
			
		</section>
   </>
  )
}
